<template>
  <div class="offer-wrapper" @click="showBuyPopupAction">
    <div>
      <div class="lot-image-wrapper">
        <img class="lot-image" :src="`/img/lots/lot-${imagePath}.png`" alt="pearls" />
      </div>
      <h5>{{ $t(packKey) }}</h5>
      <h5 v-if="packDescriptionKey" class="description">{{ $t(packDescriptionKey) }}</h5>
    </div>
    <div>
      <p class="offer-reward">{{ formatNumber(tokenReward, ',') }} {{ $t(token.label, { count: 3 }) }}</p>
      <p v-if="pearlBlackReward > 0" class="offer-reward">
        &nbsp; + {{ formatNumber(pearlBlackReward, ',') }} {{ $t(tokensConfig.blackPearl.label, { count: 3 }) }}
      </p>
      <div class="usdt-wrapper">
        <img src="/img/icons/usdt.svg" loading="lazy" alt="" width="24" />
        <p class="price">{{ formatNumber(price, ',') }} {{ $t('coreUsdt') }}</p>
      </div>
    </div>

    <el-dialog
      v-if="showBuyPopup"
      v-model="showBuyPopup"
      :title="$t('buypackButtonDialogTItle')"
      :append-to-body="true"
      :fullscreen="isMobile"
      class="buy-popup"
    >
      <div class="placeholder">
        <img class="lot-image-popup" :src="`/img/lots/lot-${imagePath}.png`" alt="pearls" />
      </div>
      <h5>{{ $t(packKey) }}</h5>
      <h5 v-if="packDescriptionKey" class="description">{{ $t(packDescriptionKey) }}</h5>
      <div class="price-row">
        <p class="mg-bottom-0">{{ $t('corePrice') }}</p>
        <div class="usdt-wrapper">
          <img src="/img/icons/usdt.svg" loading="lazy" alt="" width="24" />
          <p class="price">{{ formatNumber(price, ',') }} {{ $t('coreUsdt') }}</p>
        </div>
      </div>
      <div class="price-row">
        <p class="mg-bottom-0">{{ $t('buypackYouGet') }}:</p>
        <div class="mg-bottom-0 token-details-info">
          {{ formatNumber(tokenReward, ',') }} {{ $t(token.label, { count: 3 }) }}
          <div v-if="pearlBlackReward > 0">
            &nbsp; + {{ formatNumber(pearlBlackReward, ',') }} {{ $t(tokensConfig.blackPearl.label, { count: 3 }) }}
          </div>
        </div>
      </div>
      <div v-if="address" class="price-row balance">
        <p class="mg-bottom-0">{{ $t('buypackButtonYourBalance') }}</p>
        <div class="usdt-wrapper">
          <img src="/img/icons/usdt.svg" loading="lazy" alt="" width="24" />
          <span
            v-if="balance !== ''"
            class="price small"
            :class="[{ 'text-green': isEnoughBalance }, { 'text-red': !isEnoughBalance }]"
          >
            {{ formatNumber(balance, ',') }} {{ $t('coreUsdt') }}
          </span>
        </div>
      </div>

      <div class="notice">
        <el-checkbox id="agree-cb" v-model="agreeToRules" type="checkbox" class="checkbox" size="large">
          {{ $t('buypackButtonAcknowledgeLabel', { label: $t(token.label) }) }}
        </el-checkbox>
      </div>

      <div class="btns-wrapper">
        <button
          :loading="loadingBuyBtn || loadingAllowBtn"
          class="btn-primary"
          size="large"
          :disabled="!agreeToRules || loadingBuyBtn || loadingAllowBtn"
          @click="checkAndBuyPack(price)"
        >
          <span>
            {{ $t('buypackButtonBuyLock') }}
            <span
              class="line-rounded-icon link-icon-right"
              style="
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                  skew(0deg);
                transform-style: preserve-3d;
              "
              ></span
            >
          </span>
          <span v-if="allowConfirmCount && loadingAllowBtn">
            &nbsp;{{ allowConfirmCount }} / {{ blockchain.minConfirmationsCount }}
          </span>

          <span v-if="buyConfNumber && loadingBuyBtn">
            &nbsp;{{ buyConfNumber }} / {{ blockchain.minConfirmationsCount }}
          </span>
        </button>
      </div>
    </el-dialog>

    <el-dialog
      v-if="showSuccessPopup"
      v-model="showSuccessPopup"
      :fullscreen="isMobile"
      :title="$t('buypackConfirmed')"
      :append-to-body="true"
      class="popup-confirm buy-popup"
    >
      <div class="placeholder">
        <img class="lot-image-popup" :src="`/img/lots/lot-${imagePath}.png`" alt="pearls" />
      </div>

      <div class="price-wrap">
        <div>{{ $t('buypackButtonBoughtTokens', { label: $t(token.label) }) }}:</div>
        <div class="price-wrap-right">
          <span>{{ formatNumber(tokenReward, ',') }} {{ $t(token.label) }} </span>
          <span v-if="pearlBlackReward > 0">
            &nbsp; + {{ formatNumber(pearlBlackReward, ',') }} {{ $t(tokensConfig.blackPearl.label) }}
          </span>
        </div>
      </div>

      <div class="price-wrap">
        <div>{{ $t('buypackButtonSpent') }}</div>
        <div class="price-wrap-right">
          <img src="/img/icons/usdt.svg" loading="lazy" alt="" width="24" />
          <span class="balance-spec">{{ formatNumber(price, ',') }} {{ $t('coreUsdt') }}</span>
        </div>
      </div>

      <div v-if="address" class="price-wrap medium">
        <div>{{ $t('appStatisticTableTransaction') }}</div>
        <div class="price-wrap-right">
          <a :href="linkToTransaction" target="_blank">{{ $t('appStatisticExplorer') }}</a>
        </div>
      </div>

      <div class="btns-wrapper">
        <button class="btn-primary" size="large" @click="showSuccessPopup = false">OK</button>
      </div>
    </el-dialog>

    <el-dialog
      v-if="showCancelPopup"
      v-model="showCancelPopup"
      class="popup-canceled buy-popup"
      :fullscreen="isMobile"
      :title="$t('referralPopupCanceledTitle')"
      :append-to-body="true"
    >
      <div class="placeholder">
        <img class="lot-image-popup" :src="`/img/lots/lot-${imagePath}.png`" alt="pearls" />
      </div>

      <p>{{ $t(cancelError) }}</p>
      <p>
        <b class="flex-icon"
          >{{ $t('buypackButtonSpent') }}:&nbsp;&nbsp;<img
            class="small-icon"
            src="/img/icons/usdt.svg"
            loading="lazy"
            alt=""
            width="16"
          />0 {{ $t('coreUsdt') }}</b
        >
      </p>

      <div class="btns-wrapper">
        <button class="btn-primary" size="large" @click="showCancelPopup = false">OK</button>
      </div>
    </el-dialog>

    <el-dialog
      v-if="showValidationAddressPopup"
      v-model="showValidationAddressPopup"
      class="popup-canceled buy-popup"
      :fullscreen="isMobile"
      :title="$t('buypackButtonIssuedAddress')"
      :append-to-body="true"
    >
      <div class="placeholder">
        <img class="lot-image-popup" :src="`/img/lots/lot-${imagePath}.png`" alt="pearls" />
      </div>
      <p style="white-space: pre-line">
        {{ failedValidationMessage }}
      </p>

      <div class="btns-wrapper">
        <button class="btn-primary" size="large" @click="showValidationAddressPopup = false">
          {{ $t('coreClose') }}
        </button>
      </div>
    </el-dialog>

    <el-dialog
      v-if="showLowBalance"
      v-model="showLowBalance"
      :title="$t('buypackButtonInsufficientFunds')"
      :fullscreen="isMobile"
      class="buy-popup"
      :append-to-body="true"
    >
      <div>
        <p class="packPriceWithLogo">
          <span v-html="$t('buypackButtonPurchaseCosts')" />
          <b class="flex-icon"
            ><img class="small-icon" src="/img/icons/usdt.svg" loading="lazy" alt="" width="16" />{{
              formatNumber(price, ',')
            }}
            {{ $t('coreUsdt') }}</b
          >.
        </p>
        <p>{{ $t('buypackButtonNotEnoughFunds') }}</p>
        <p v-html="$t('buypackButtonPancakeBalance')"></p>
      </div>
      <div class="btns-wrapper">
        <a href="https://pancakeswap.finance/?chain=bsc" target="_blank">
          <button class="btn-primary" size="large" @click="showCancelPopup = false">
            <span>{{ $t('buypackButtonTopUp') }}</span>
          </button>
        </a>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { BigNumber } from 'bignumber.js';
import { mapActions } from 'pinia';
import { useMainStore } from '~/stores/mainStore';
import { formatNumber, getCancelErrorMessage, modifyHistoryHashStatus, saveHashToLocalStorage } from '~/utils';
import PearlApiService from '~/composables/PearlApiService';
import useEnvs from '~/composables/useEnvs';
import { LOW_RISK_VALUE, apiUrls, getPopupText, tokensConfig } from '~/utils/constants';
import { useWalletInfo, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/vue';
import { useStakingMethods, useTokensReader } from '#imports';
import type { IUserModel } from '~/types/apiService';
import { ZeroAddress } from 'ethers';

export default defineComponent({
  name: 'BuyPackButton',
  props: {
    packKey: {
      required: true,
      type: String
    },
    price: {
      required: true,
      type: Number
    },
    tokenReward: {
      required: true,
      type: Number
    },
    pearlBlackReward: {
      type: Number,
      default: 0
    },
    imagePath: {
      required: true,
      type: String
    },
    name: {
      required: false,
      type: String,
      default: ''
    },
    token: {
      required: true,
      type: Object
    },
    packDescriptionKey: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['success'],
  async setup() {
    const { open } = useWeb3Modal();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { walletInfo } = useWalletInfo();
    const { blockchain, apiUrl } = useEnvs();
    const route = useRoute();
    const refId = computed(() => route.query?.ref);
    const store = useMainStore();
    const { approve, buyPack, buyPackAndRegisterInReferralProgram } = useStakingMethods();
    const { allowance, availableTokens, lockPeriod } = useTokensReader();
    const { t } = useI18n();

    const { data: userData, status: userDataStatus } = await useFetch<IUserModel>(apiUrls.user, {
      baseURL: apiUrl,
      watch: [address],
      query: {
        address: address
      },
      immediate: true
    });

    const { data: referrerData } = await useFetch<IUserModel['user']>(`${apiUrls.user}/${refId.value}`, {
      baseURL: apiUrl,
      watch: [refId],
      immediate: true
    });

    return {
      blockchain,
      apiUrl,
      address,
      chainId,
      isConnected,
      open,
      walletProvider,
      walletInfo,
      store,
      approve,
      buyPack,
      buyPackAndRegisterInReferralProgram,
      allowance,
      availableTokens,
      lockPeriod,
      route,
      userData,
      userDataStatus,
      referrerData,
      t,
      tokensConfig
    };
  },
  data(): {
    allowConfirmCount: number;
    allowedCount: number;
    loadingAllowed: boolean;
    loadingAllowBtn: boolean;
    loadingBuyBtn: boolean;
    allowed: boolean;
    btnLoading: boolean;
    buyConfNumber: number;
    showBuyPopup: boolean;
    balance: string;
    loadingBalance: boolean;
    showLowBalance: boolean;
    showCancelPopup: boolean;
    showValidationAddressPopup: boolean;
    failedValidationMessage: string;
    showSuccessPopup: boolean;
    lastHash: string;
    cancelError: string;
    agreeToRules: boolean;
  } {
    return {
      allowConfirmCount: 0,
      allowedCount: 0,
      loadingAllowed: false,
      loadingAllowBtn: false,
      loadingBuyBtn: false,
      allowed: false,
      btnLoading: false,
      buyConfNumber: 0,
      showBuyPopup: false,
      balance: '',
      loadingBalance: false,
      showLowBalance: false,
      showCancelPopup: false,
      showValidationAddressPopup: false,
      failedValidationMessage: '',
      showSuccessPopup: false,
      lastHash: '',
      cancelError: '',
      agreeToRules: false
    };
  },
  computed: {
    isEnoughBalance(): boolean {
      return new BigNumber(this.balance).gte(this.price);
    },
    linkToTransaction(): string {
      return this.blockchain.blockScanUrl + '/tx/' + this.lastHash;
    },
    isMobile(): boolean {
      if (window && window.innerWidth) {
        return window.innerWidth < 720;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions(useMainStore, ['updateVersion']),
    async checkAndBuyPack(quantity: number) {
      this.loadingBuyBtn = true;
      this.cancelError = '';
      if (!this.address || !this.walletProvider) {
        return;
      }
      this.allowConfirmCount = 0;
      try {
        const allowedCount = await this.allowance(
          this.address,
          this.blockchain.contracts.USDT,
          this.token.name.contract
        );

        await this.updateBalance();
        if (!this.isEnoughBalance) {
          this.showLowBalance = true;
          return;
        }

        // get allowance equal to quantity
        if (new BigNumber(Number(allowedCount)).isLessThan(quantity)) {
          this.loadingAllowBtn = true;
          const receipt = await this.approve(
            this.blockchain.contracts.USDT,
            quantity,
            () => {
              this.allowConfirmCount += 1;
            },
            this.token.name.contract
          );
          if (!receipt?.hash) return;
          saveHashToLocalStorage(this.t('notificationApprove'), receipt.hash);
          this.updateVersion();

          await receipt?.wait(this.blockchain.minConfirmationsCount);
          const confirmations = await receipt?.confirmations();

          if (confirmations >= this.blockchain.minConfirmationsCount && receipt?.hash) {
            this.loadingAllowBtn = false;
            this.buyPackHandler(quantity);
            modifyHistoryHashStatus(receipt?.hash, 'Done');
            this.updateVersion();
          }
        } else {
          this.loadingBuyBtn = true;
          await this.buyPackHandler(quantity);
        }
      } catch (error: unknown) {
        const currentProviderKind = this.walletInfo?.name;

        this.loadingAllowBtn = false;
        this.loadingBuyBtn = false;
        this.showBuyPopup = false;
        if (currentProviderKind) {
          this.cancelError = getCancelErrorMessage(error, currentProviderKind);
        }
        this.showCancelPopup = true;
        console.error(error);
      }
    },
    async buyPackHandler(quantity: number) {
      this.lastHash = '';
      this.buyConfNumber = 0;
      this.loadingBuyBtn = true;
      this.cancelError = '';
      try {
        const receipt =
          this.route.query?.ref && !this.userData && this.userDataStatus !== 'pending' && this.userDataStatus !== 'idle'
            ? await this.buyPackAndRegisterInReferralProgram(
                quantity.toString(),
                this.token.name.contract,
                this.referrerData?.address || ZeroAddress,
                () => {
                  this.buyConfNumber += 1;
                }
              )
            : await this.buyPack(quantity.toString(), this.token.name.contract, () => {
                this.buyConfNumber += 1;
              });

        if (!receipt?.hash) return;

        saveHashToLocalStorage(
          `${this.t('notificationBuy')} ${this.t(this.token.label)} ${this.t('notificationPack')} (${this.tokenReward} ${this.t(this.token.label).toUpperCase()} ${this.pearlBlackReward > 0 ? `+ ${this.pearlBlackReward} ${this.t(tokensConfig.blackPearl.label)}` : ''})`,
          receipt.hash
        );
        this.updateVersion();

        await receipt.wait(this.blockchain.minConfirmationsCount);

        await PearlApiService.pushActivity(this.apiUrl, {
          address: this.address ?? '',
          transactionHash: receipt.hash,
          value: this.tokenReward,
          type: 'PURCHASE'
        });

        const lockPeriodValue = await this.lockPeriod(this.token.name.contract);

        const stakeId = receipt.hash;
        const userId = this.address;
        const tokenAmount = this.tokenReward;
        const stakePeriod = new Date(Date.now() + Number(lockPeriodValue) * 1000).toLocaleString();

        useTrackEvent('stake_start', {
          stakeId,
          currency: 'USD',
          value: quantity.toString(),
          tokenAmount,
          userId,
          stakePeriod
        });

        modifyHistoryHashStatus(receipt.hash, 'Done');
        this.updateVersion();
        this.loadingBuyBtn = false;
        this.$emit('success');
        this.showBuyPopup = false;
        this.showSuccessPopup = true;
        this.lastHash = receipt.hash;
      } catch (error: unknown) {
        console.error(error, 'error from buy pack');
        const currentProviderKind = this.walletInfo?.name ?? '';

        this.showBuyPopup = false;
        this.loadingBuyBtn = false;

        this.cancelError = getCancelErrorMessage(error, currentProviderKind);
        this.showCancelPopup = true;
      }
    },
    async updateBalance(): Promise<void> {
      this.balance = '';
      if (!this.address) {
        return;
      }
      this.loadingBalance = true;

      try {
        const balance = await this.availableTokens(this.address, this.blockchain.contracts.USDT);
        this.balance = Number(balance).toFixed(2);
      } finally {
        this.loadingBalance = false;
      }
    },
    async showBuyPopupAction() {
      if (!this.address) {
        this.open();
        return;
      }

      if (!this.isEnoughBalance) {
        this.showBuyPopup = true;
        await this.updateBalance();
        return;
      }

      const { data: validationAddress, status } = await PearlApiService.validadeAddress(
        this.apiUrl,
        this.address,
        this.price.toString()
      );
      if (status >= 400) {
        this.showValidationAddressPopup = true;
        this.failedValidationMessage = getPopupText(status);
        return;
      }
      if (validationAddress.risk && validationAddress?.risk?.score <= LOW_RISK_VALUE) {
        this.showBuyPopup = true;
        await this.updateBalance();
      } else {
        this.showValidationAddressPopup = true;
        this.failedValidationMessage = getPopupText(
          status,
          validationAddress?.risk?.score,
          validationAddress?.risk?.descriptions
        );
      }
    },
    async onSuccess() {
      await this.checkAndBuyPack(this.price);
    },
    formatNumber(num: number | string, delim: string) {
      return formatNumber(num, delim);
    }
  }
});
</script>

<style lang="scss">
h5 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Grenze, sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.333em;
  text-align: center;
  width: 100%;
}

.description {
  color: #ffe604;
  font-weight: 500;
  margin-top: -10px;
}

.lot-image {
  max-width: 90%;
  display: inline-block;
  height: inherit;
  object-fit: contain;
}

.lot-image-popup {
  max-width: 100%;
  max-height: 200px;
  margin: 10px 0;
}

.price-wrap {
  display: flex;
  color: var(--second-text-color);
  font-size: 18px;
  font-weight: 400;
  margin-top: 16px;
  font-family: Eczar, sans-serif;

  @media screen and (max-width: 900px) {
    font-size: 18px;
  }
}

.price-wrap-right {
  margin-left: auto;
  color: var(--main-text-color);
  display: flex;
  text-align: right;
}

.medium {
  text-transform: capitalize;
  // font-size: 20px;
  font-weight: normal;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 8px;
  padding-bottom: 8px;
}

.placeholder {
  border-radius: 12px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.lot-image-wrapper {
  width: 100%;
  height: 125px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: visible;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .lot-image-wrapper {
    height: 100px;
  }
}

.offer-wrapper {
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  height: 100%;
}

.usdt-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.offer-reward {
  margin-bottom: 0;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  h5 {
    font-size: 16px;
  }
}

.price {
  color: var(--main-text-color);
  margin-bottom: 0;
  padding-left: 8px;
  font-size: 24px;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .price {
    font-size: 16px;
  }
}

@media screen and (max-width: 479px) {
  .price {
    padding-left: 8px;
    font-size: 13px;
    line-height: 144%;
  }
}

.text-green {
  color: var(--confirm-color);
}

.text-red {
  color: var(--canceled-color);
}

.el-button--primary {
  margin-top: 6px;
}

.btns-wrapper {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.buy-btn {
  display: flex;
  padding: 12px 38px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 86px;
  background: var(--main-blue);
  text-align: center;
  font-size: 24px;
  color: var(--main-text-color);
  border: 1px solid transparent;

  &:active,
  &:focus,
  &:hover {
    background: transparent;
    border: 1px solid #939393;
  }
}

.subtitle {
  text-align: center;
  font-weight: 700;
  color: var(--main-text-color);
  font-size: 24px;
}

.popup-text {
  color: var(--main-text-color);
  text-align: center;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  margin-right: 6px;
}

.notice {
  margin-top: 12px;
  margin-bottom: 12px;
}

.balance-spec {
  padding-left: 8px;
}

.price-row {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
  font-size: 18px;
  color: var(--second-text-color);

  .token-details-info {
    text-align: right;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.price-row.balance {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 8px;
  padding-bottom: 8px;
}

.mg-bottom-0 {
  margin-bottom: 0;
}

.price.small {
  font-size: 18px;
  text-align: right;
}

.flex-icon {
  display: inline-flex;
  align-items: center;
}

.small-icon {
  margin-right: 4px;
}

.packPriceWithLogo {
  display: flex;
  gap: 5px;
  align-items: center;
  text-wrap: nowrap;
}
</style>

<style>
.el-checkbox {
  white-space: normal;
}
</style>
